export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37')
];

export const server_loads = [2];

export const dictionary = {
		"/(app)": [6,[2]],
		"/(app)/agenda": [7,[2]],
		"/(app)/bo-user": [8,[2]],
		"/(app)/bo-user/create": [10,[2]],
		"/(app)/bo-user/[uid]": [9,[2]],
		"/(app)/client-credentials": [11,[2]],
		"/(app)/client-credentials/create": [12,[2]],
		"/(app)/configuration": [13,[2]],
		"/(app)/connections": [14,[2]],
		"/(app)/contacts": [15,[2]],
		"/(app)/login": [16,[3]],
		"/(app)/orders": [17,[2]],
		"/(app)/orders/[slug]": [18,[2]],
		"/(app)/payments": [19,[2]],
		"/(app)/products": [20,[2]],
		"/(app)/products/[sku]": [21,[2]],
		"/(app)/reset-password": [22,[4]],
		"/(app)/set-password": [23,[5]],
		"/(app)/stripe/[[brand]]/payout/[key]": [25,[2]],
		"/(app)/stripe/[[brand]]/preview/[[creditDebitId]]": [26,[2]],
		"/(app)/stripe/[[brand]]": [24,[2]],
		"/(app)/style-genie-clusters": [30,[2]],
		"/(app)/style-genie-config": [31,[2]],
		"/(app)/style-genie": [27,[2]],
		"/(app)/style-genie/style-genie-dash": [29,[2]],
		"/(app)/style-genie/[uid]": [28,[2]],
		"/(app)/tenant": [32,[2]],
		"/(app)/tenant/create": [34,[2]],
		"/(app)/tenant/[code]": [33,[2]],
		"/(app)/translations": [35,[2]],
		"/(app)/users": [36,[2]],
		"/(app)/users/[user_id]": [37,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';